@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@200&display=swap');

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li,
fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary, time, mark, audio, video 
  
{ margin: 0; padding: 0; border: 0 }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section { display: block }
 
a { text-decoration: none }

*{ box-sizing: border-box }
    
body { line-height: 1; height: 100% }

ol, ul { list-style: none; }
    
blockquote, q { quotes: none; }
    
blockquote:before, blockquote:after, q:before, q:after { content: ''; content: none }
    
table { border-collapse: collapse; border-spacing: 0 }

::-webkit-scrollbar { width: 0; height: 0 }

html { scroll-behavior: smooth; height: 100% }

#root { width: 100%; height: 100% }

main{ width: 100%; height: 100%; font-family: 'Source Code Pro', monospace }