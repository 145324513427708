#certificates{
    width: 100%;
    height: 1000px;
    padding: 5%;
    text-align: center;
    opacity: 0;
}

#certificates > h1 {
    margin: var(--h1-margin);
    font-size: 35px;
    font-weight: 800;
    color: var(--secundary-color);
    text-shadow: 0 0 0.1em var(--pulse-blue), 0 0 0.1em var(--pulse-blue-shadow),0 0 
    0.1em var(--pulse-blue-shadow);
}

#certificates > div {
    background: rgba(40, 42, 54, 0.6);
    border-radius: 20px;
    width: 100%;
    height: 85%;
    padding: 30px;
    display: flex;
}

.central-certificate{
    position: relative;
    width: 80%;
    height: 100%;
    background: rgba(40, 42, 54, 0.5);
    padding: 15px;
    border-radius: 20px 0 0 20px;
}

.central-certificate > img { 
    width: 100%; 
    height: 100%; 
    object-fit: contain;
    user-select: none;
}

.side-certificate{
    width: 20%;
    height: 100%;
    background: rgba(40, 42, 54, 1);
    padding: 15px;
    display: flex;
    flex-direction: column;
    border-radius: 0 20px 20px 0;
    overflow: scroll;
}

.side-certificate > img { 
    margin: 15px; 
    border-radius: 15px 
}

.arrow-left { 
    position: absolute; 
    font-size: 4rem;
    top: 50%;
    left: 0;
}

.arrow-right { 
    position: absolute;
    font-size: 4rem;
    top: 50%; 
    right: 0;
}

.arrow-left:hover, .arrow-right:hover { cursor: pointer }

@media screen and (max-width: 1024px) {
    #certificates{height: auto;
    }
    .central-certificate { width: 100% }
    .side-certificate{ display: none; }
}
