.nav {
  width: max-content;
  padding: 0.7rem 1.7rem;
  z-index: 2;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 2rem;
  display: flex;
  gap: 0.8rem;
  border-radius: 3rem;
  backdrop-filter: blur(15px);
  opacity: 0;
}

.nav a { 
  background: transparent;
  padding: 0.6rem;
  border-radius: 50%;
  display: flex;
  color: var(--secundary-color);
  font-size:1.3rem;
}

.nav a:hover{ background: var(--blur-bg); border-radius: 3rem }

.hideNav{ display: none }